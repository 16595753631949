<template>
    <el-container>
        <el-main class="list" v-loading="loading">
            <div class="infoList">
                <el-row :gutter="30" v-if="pageType === 'gride'">
                    <el-col v-for="item in list" :key="item.id">
                        <gride shadow :info="item" isList></gride>
                    </el-col>
                </el-row>
                <div v-else>
                    <Row class="row" :info="item" v-for="item in list" :key="item.id" isList />
                </div>
            </div>

            <empty-page v-if="empty"></empty-page>

            <el-footer v-else>
                <el-pagination
                    @size-change="onSizeChange"
                    @current-change="onCurrentChange"
                    :current-page="page"
                    :page-sizes="pageSizes"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalElements"
                >
                </el-pagination>
            </el-footer>
        </el-main>
    </el-container>
</template>
<script>
import { mapState } from 'vuex';
import Row from '../../components/news/Row.vue';
import Gride from '../../components/news/Gride.vue';
import pageableList from '../../mixins/pageableList';
import { InformationType } from '../../utils/AppState';
export default {
    name: 'NewsList',
    mixins: [pageableList],
    data() {
        return {
            url: '/information/all',
            formType: { body: 'json' },
            defaultType: '',
            pageSize: 10
        };
    },
    computed: {
        listQuery() {
            return {
                query: { type: this.type || '', status: 'PASS' },
                sort: 'top,desc;createdAt,desc;sort,desc'
            };
        },
        routeQuery() {
            return {
                type: this.type || ''
            };
        },
        type() {
            return this.$route.query.type || '';
        },
        pageType() {
            return InformationType.has(this.type) ? InformationType.get(this.type).type : '';
        },
        pageSizes() {
            if (this.type !== 'NEW_PRODUCT_RELEASE') {
                return [10, 20, 30, 40];
            } else {
                return [12, 24, 36, 48];
            }
        }
    },
    watch: {
        type() {
            if (this.type !== 'NEW_PRODUCT_RELEASE') {
                this.pageSize = 10;
            } else {
                this.pageSize = 12;
            }
            this.$nextTick(() => {
                this.page = 1;
                this.getData();
            });
        }
    },
    components: {
        Row,
        Gride
    },
    mounted() {
        if (!this.$route.query.type) {
            this.defaultType = [...InformationType][0][0];
        }
        if (this.type !== 'NEW_PRODUCT_RELEASE') {
            this.pageSize = 10;
        } else {
            this.pageSize = 12;
        }
        this.getData();
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.infoList {
    min-height: 600px;
}
.list {
    padding: 0 0;
    margin: 0 0 0 30px;
    min-height: 50vh;
    position: relative;
    z-index: 1;
}
.el-main {
    overflow: hidden;
}
.menu {
    background: #ffffff;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    color: #818999;
    line-height: 80px;

    .menu-item {
        height: 80px;
        cursor: pointer;
        position: relative;

        &:hover {
            color: #ffa526;
        }

        &.active {
            background: #292c33;
            color: #ffa526;
            &::before {
                position: absolute;
                left: 0;
                width: 2px;
                top: 0;
                bottom: 0;
                background-color: #ffa526;
            }
        }
    }
}

.row {
    margin-bottom: 30px;
}

.el-col {
    margin-bottom: 30px;
    width: 33.3%;
}

@media screen and (max-width: 1000px) {
    .el-col {
        width: 50%;
    }
}

.el-footer {
    padding: 20px;
    display: flex;
    justify-content: center;
    .el-pagination {
        // margin: 0 auto;
    }
}

.floatMenu {
    position: fixed;
    z-index: 20;
    top: 120px;
    width: 216px;
    left: 50px;
}

@media screen and (min-width: 1690px) {
    .floatMenu {
        left: calc(50% - 745px);
    }
}
</style>
